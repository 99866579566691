export const environment = {
  apiBaseUrl: 'https://daikin-api.cloudzmall.com/api/',
  production: false,
  msalConfig: {
    auth: {
      clientId: 'd4a97ba4-a046-4b9a-b617-9076e7b7d02c',
      authority:
        'https://login.microsoftonline.com/7bc5ac40-7bb8-47f7-8b39-802245ab84cd',
      redirectUrl: '/',
    },
  },
};
