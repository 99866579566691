import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-error-modal',
  standalone: true,
  imports: [],
  templateUrl: './error-modal.component.html',
  styleUrl: './error-modal.component.scss',
})
export class ErrorModalComponent {
  errorContent: string = '';

  constructor(public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config.data) {
      this.errorContent = this.config.data.errorContent;
    }
  }
}
