import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, throwError } from 'rxjs';
import { ErrorModalComponent } from '../features/modals/error-modal/error-modal.component';
import { LoaderService } from '../shared/loader/loader.service';
import { LocalStorageServiceService } from './local-storage-service.service';
import { INTERNAL_SERVER_CODE } from '@constants/meta-data';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private loaderService: LoaderService,
    private localStorage: LocalStorageServiceService
  ) {
    this.checkScreenSize();
  }

  ref!: DynamicDialogRef;
  isMobileView: boolean = false;

  private getAccessToken(): string | null {
    return this.localStorage.getAccessToken();
  }

  private createHeaders(moreHeaders?: any): HttpHeaders {
    const plantId = localStorage.getItem('plantId');
    let headers: any = {
      Authorization: `Bearer ${this.getAccessToken()}`,
      ...moreHeaders,
    };
    if (plantId) {
      headers['plantId'] = plantId;
    }
    return new HttpHeaders(headers);
  }

  getRequest(
    url: string,
    moreHeaders?: any,
    moreOptions?: any,
    isLoader: boolean = true
  ): Promise<any> {
    let options: any = {
      headers: this.createHeaders(moreHeaders),
    };
    if (moreOptions) {
      for (let key in moreOptions) {
        options[key] = moreOptions[key];
      }
    }
    if (isLoader) this.loaderService.show();
    return this.http
      .get(url, options)
      .pipe(
        catchError((error) => {
          this.loaderService.hide();
          let errorMessage = '';
          if (error.status === INTERNAL_SERVER_CODE) {
            errorMessage = 'Unable to retrieve data. Please refresh the page or try again later';
          } else errorMessage = error.error?.errors[0]?.message;
          this.openErrorModal(errorMessage);
          return throwError(error);
        })
      )
      .toPromise()
      .then((response) => {
        this.loaderService.hide();
        return response;
      });
  }

  postRequest(
    url: string,
    data: object,
    moreHeaders?: any,
    moreOptions?: any,
    isLoader: boolean = true
  ): Promise<any> {
    let options: any = {
      headers: this.createHeaders(moreHeaders),
    };
    if (moreOptions) {
      for (let key in moreOptions) {
        options[key] = moreOptions[key];
      }
    }
    if (isLoader) this.loaderService.show();
    return this.http
      .post(url, data, options)
      .pipe(
        catchError((error) => {
          this.loaderService.hide();
          let errorMessage = '';
          if (error.status === INTERNAL_SERVER_CODE) {
            errorMessage = 'Unable to process request. Please refresh the page or try again later';
          } else errorMessage = error.error?.errors[0]?.message;
          this.openErrorModal(errorMessage);
          return throwError(error);
        })
      )
      .toPromise()
      .then((response) => {
        this.loaderService.hide();
        return response;
      });
  }

  deleteRequest(
    url: string,
    moreHeaders?: any,
    moreOptions?: any
  ): Promise<any> {
    let options: any = {
      headers: this.createHeaders(moreHeaders),
    };
    if (moreOptions) {
      for (let key in moreOptions) {
        options[key] = moreOptions[key];
      }
    }
    this.loaderService.show();
    return this.http
      .delete(url, options)
      .pipe(
        catchError((error) => {
          this.loaderService.hide();
          let errorMessage = '';
          if (error.status === INTERNAL_SERVER_CODE) {
            errorMessage = 'Unable to retrieve data. Please refresh the page or try again later';
          } else errorMessage = error.error?.errors[0]?.message;
          this.openErrorModal(errorMessage);
          return throwError(error);
        })
      )
      .toPromise()
      .then((response) => {
        this.loaderService.hide();
        return response;
      });
  }

  putRequest(
    url: string,
    data: any,
    moreHeaders?: any,
    moreOptions?: any
  ): Promise<any> {
    let options: any = {
      headers: this.createHeaders(moreHeaders),
    };
    if (moreOptions) {
      for (let key in moreOptions) {
        options[key] = moreOptions[key];
      }
    }
    this.loaderService.show();
    return this.http
      .put(url, data, options)
      .pipe(
        catchError((error) => {
          this.loaderService.hide();
          let errorMessage = '';
          if (error.status === INTERNAL_SERVER_CODE) {
            errorMessage = 'Unable to retrieve data. Please refresh the page or try again later';
          } else errorMessage = error.error?.errors[0]?.message;
          this.openErrorModal(errorMessage);
          return throwError(error);
        })
      )
      .toPromise()
      .then((response) => {
        this.loaderService.hide();
        return response;
      });
  }

  patchRequest(
    url: string,
    data: any,
    moreHeaders?: any,
    moreOptions?: any
  ): Promise<any> {
    let options: any = {
      headers: this.createHeaders(moreHeaders),
    };
    if (moreOptions) {
      for (let key in moreOptions) {
        options[key] = moreOptions[key];
      }
    }
    this.loaderService.show();
    return this.http
      .patch(url, data, options)
      .pipe(
        catchError((error) => {
          this.loaderService.hide();
          let errorMessage = '';
          if (error.status === INTERNAL_SERVER_CODE) {
            errorMessage = 'Unable to retrieve data. Please refresh the page or try again later';
          } else errorMessage = error.error?.errors[0]?.message;
          this.openErrorModal(errorMessage);
          return throwError(error);
        })
      )
      .toPromise()
      .then((response) => {
        this.loaderService.hide();
        return response;
      });
  }

  openErrorModal(errorContent?: string) {
    this.loaderService.hide();
    this.ref = this.dialogService.open(ErrorModalComponent, {
      data: {
        errorContent: errorContent,
      },
      width: '384px',
      dismissableMask: true,
      styleClass: this.isMobileView
        ? 'custom-modal-border-radius mobile-modal'
        : 'custom-modal-border-radius',
    });
  }

  checkScreenSize() {
    this.isMobileView = window.innerWidth < 768;
  }

  downloadExcel(
    url: string,
    data: object,
    moreHeaders?: any,
    moreOptions?: any
  ): Promise<any> {
    let options: any = {
      headers: this.createHeaders(moreHeaders),
    };
    if (moreOptions) {
      for (let key in moreOptions) {
        options[key] = moreOptions[key];
      }
    }
    options['responseType'] = 'text';

    return this.http
      .post(url, data, options)
      .pipe(
        catchError((error) => {
          const errors = JSON.parse(error.error);
          let errorMessage = '';
          if (error.status === INTERNAL_SERVER_CODE) {
            errorMessage = 'Unable to retrieve data. Please refresh the page or try again later';
          } else errorMessage = errors.errors[0]?.message;
          this.openErrorModal(errorMessage);
          return throwError(error);
        })
      )
      .toPromise()
      .then((response) => {
        return response;
      });
  }
}
