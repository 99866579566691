export const LINE_STATUS_CARDS_DATA = [
  {
    name: 'Plan (Units)',
    value: '0',
    imgUrl: 'assets/images/increament-img.svg',
  },
  {
    name: 'Actual (Units)',
    value: '0',
    imgUrl: 'assets/images/increament-blue-img.svg',
  },
  {
    name: 'Difference',
    value: '0',
    imgUrl: 'assets/images/decreament-img.svg',
  },
  {
    name: 'Running Time (Mins)',
    value: '0',
    imgUrl: 'assets/images/line-status-image-1.svg',
  },
  {
    name: 'Stop Time (Mins)',
    value: '0',
    imgUrl: 'assets/images/line-status-image-2.svg',
  },
  {
    name: 'Efficiency Percentage',
    value: '0%',
    imgUrl: 'assets/images/line-status-image-3.svg',
  },
];

export enum PRODUCTION_LOSS {
  PRODUCTION_HALTED = 0,
  BREAK_DOWN = 1,
  PRODUCTION = 2,
  BREAK = 3,
}

export enum PRODUCTION_LOSS_TEXT {
  PRODUCTION_HALTED = 'Production Halted',
  BREAK_DOWN = 'Breakdown',
  PRODUCTION = 'Production',
  BREAK = 'Break',
}

export const PRODUCTION_LOSS_DATA = [
  {
    id: PRODUCTION_LOSS.PRODUCTION_HALTED,
    name: PRODUCTION_LOSS_TEXT.PRODUCTION_HALTED,
  },
  { id: PRODUCTION_LOSS.BREAK_DOWN, name: PRODUCTION_LOSS_TEXT.BREAK_DOWN },
];

export const PRODUCTIVITY_STATUS_CARDS_DATA = [
  {
    name: 'Yearly Productivity Target (%)',
    value: '0',
    imgUrl: 'assets/images/pro-target.svg',
  },
  {
    name: 'Yearly Productivity Actual (%)',
    value: '0',
    imgUrl: 'assets/images/pro-actual.svg',
  },
];

export const USER_ROLES = [
  {
    name: 'Admin',
    id: 1,
  },
  {
    name: 'User',
    id: 2,
  },
];

export const DAILY_PRODUTION_FILTERS = [
  {
    name: 'Plan Vs Actual',
    id: 0,
  },
  {
    name: 'Target vs Actual',
    id: 1,
  },
  {
    name: 'Target, Actual & Difference',
    id: 2,
  },
  {
    name: 'Plan, Actual & Difference',
    id: 3,
  },
];

export const INTERNAL_SERVER_CODE = 500;
export const UNAUTHORIZED_ERROR_CODE = 401;

export const USER_ROLE_ID = {
  ADMIN: '1',
  USER: '2',
};
