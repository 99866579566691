import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageServiceService {
  private secretKey: string = 'my-secret-key';

  /**
   * Function to get decrypted access-token from localstorage
   * @param key string
   */
  getAccessToken() {
    return this.getItem('accessToken');
  }

  /**
   * Function to set encrypted access-token in localstorage
   * @param key string
   * @param val string or object
   */
  setAccessToken(token: any) {
    this.setItem('accessToken', token);
  }

  /**
   * Function to decrypt value
   * @param val string
   * @param secretKey string
   */
  getDescryptedVal(val: string | null, secretKey: string) {
    if (val === null) return;
    let decryptObj = crypto.AES.decrypt(val, this.secretKey);
    return _.attempt(_.invoke, decryptObj, 'toString', crypto.enc.Utf8);
  }

  /**
   * Function to get decrypted item from localstorage
   * @param key string
   */
  getItem(key: string) {
    let retrievedVal = localStorage.getItem(key);

    if (_.isNull(retrievedVal)) {
      return null;
    }
    let decryptedVal: any = this.getDescryptedVal(retrievedVal, this.secretKey);

    // value modified by user
    if (_.isError(decryptedVal) || _.isEmpty(decryptedVal)) {
      this.removeItem(key);
      return null;
    }

    let decryptedObj = _.attempt(JSON.parse.bind(null, decryptedVal));
    return _.isError(decryptedObj) ? decryptedVal : decryptedObj;
  }

  /**
   * Function to set encrypted value in localstorage
   * @param key string
   * @param val string or object
   */
  setItem(key: string, val: any) {
    let parsedVal;

    // Check whether value is an object
    if (_.isObject(val) || _.isArray(val)) {
      parsedVal = _.attempt(JSON.stringify.bind(null, val));
    }
    // If value is string
    else if (_.isString(val)) {
      parsedVal = val;
    }

    // Encrypt key and store in localStorage
    if (!_.isError(parsedVal) && !_.isUndefined(parsedVal)) {
      parsedVal = crypto.AES.encrypt(parsedVal, this.secretKey).toString();
      localStorage.setItem(key, parsedVal);
    }
  }

  /**
   * Remove specific key from localStorage
   * @param key string
   */
  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  /**
   * Function to clear LocalStorage
   */
  clearLocalStorage() {
    localStorage.clear();
  }
}
